// ConfirmDeletePopup.jsx
import React from 'react';
import { IoClose } from "react-icons/io5";
import { renderStore } from '../../store/renderStore';
import { FiDelete, FiDownload } from 'react-icons/fi';
import { RiDeleteBack2Fill, RiDeleteBinLine } from 'react-icons/ri';
import { FaRegTrashAlt } from 'react-icons/fa';
const ConfirmDeletePopup: React.FC<{ onConfirm: () => void; onCancel: () => void }> = ({ onConfirm, onCancel }) => {
  return (
    <div  className="fixed z-50 w-[455px] h-[234px] bg-[#f7f6f0] border border-gray-300 rrounded-lg shadow-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    <div className="w-[454px] h-10 pl-3 pr-2.5 py-1 left-0 top-0 absolute rounded-tl-sm rounded-tr-sm border-b border-[#edebe6] justify-between items-center inline-flex">
          <div className="text-black text-xs font-medium font-['DM Sans']">Delete project</div>
          <div onClick={onCancel} className="text-sm text-gray-500 cursor-pointer"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
      </div>
      <div className="left-[135px] top-[80px] absolute text-center text-black text-base font-normal font-['DM Sans']">Are you sure you want to<br /> delete this project?</div><div className="w-[414px] h-[52px] px-4 py-[17px] left-[20px] top-[158px] absolute bg-[#110313] rounded-lg justify-center items-center gap-2 inline-flex">
              <div className="text-center text-[#fcfcfc] text-base font-normal font-['DM Sans']" onClick={onConfirm}>Yes, delete</div>
              <div className="w-12 h-12 mt-8 relative" >
              <FaRegTrashAlt className="text-[#fcfcfc] text-base" />
                </div>
          </div>
          </div>
  );
};

export default ConfirmDeletePopup;
